/*jshint esversion: 6 */

/** If you are not familiar with ES6, check these:
 * https://googlechrome.github.io/samples/classes-es6/
 * https://www.sitepoint.com/understanding-es6-modules/
 */
import './modules/rc';
import './modules/menu';
import './modules/instascanHelper';
import './modules/serviceHelper';
import './modules/jplayerHelper';



/** Note: You can use require like: require('./demo.js'); */

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
});

// Load Events
$(document).ready(() => routes.loadEvents());

/*jshint esversion: 6 */

export default {
  init() {
    // JavaScript to be fired on the home page
    console.log('home init');
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    console.log('home finalize');
  },
};

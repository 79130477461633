/*jshint esversion: 6 */

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('common init');
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    console.log('common finalize');
  },
};

/*jshint esversion: 6 */
let scanner = new Instascan.Scanner({ video: document.getElementById('js-camera') });
scanner.addListener('scan', function (content) {
  // history.pushState({
  // }, 'Audio Muzej', './?play=' + content);
  window.location.href = './?play=' + content;
});
Instascan.Camera.getCameras().then(function (cameras) {
  if (cameras.length > 0) {
    scanner.start(cameras[0]);
  } else {
    console.error('No cameras found.');
  }
}).catch(function (e) {
  //console.error(e);
});

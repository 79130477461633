function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}

var playid = getUrlVars()['play'];


//<![CDATA[
$(document).ready(function(){
	$('.js-jplayer').jPlayer({
		ready: function (event) {
			$(this).jPlayer("setMedia", {
				title: playid,
			//	m4a: '/mp3/' + playid + '.m4a',
				//ogg: '/mp3/' + playid + '.ogg',
        mp3: '/mp3/' + playid + '.mp3'
			});
		},
		swfPath: '/mp3',
		supplied: 'm4a, ogg, mp3',
		wmode: 'window',
		useStateClassSkin: true,
		autoBlur: false,
		smoothPlayBar: true,
		keyEnabled: true,
		remainingDuration: true,
		toggleDuration: true
	});
});
//]]>
